var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v("Test List")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tests,"options":_vm.options,"server-items-length":_vm.totalData,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var ref_item = ref.item;
var groupId = ref_item.groupId;
var testId = ref_item.testId;
return [_c('div',{staticStyle:{"width":"100px"}},[_c('v-btn',{staticClass:"mb-1",attrs:{"to":{
            name: 'Result.Group.TestResult',
            params: { group_id: groupId, test_id: testId }
          },"color":"primary"}},[_vm._v("Result")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }