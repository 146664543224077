<template>
  <v-container>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>Test List</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="options.search"
          prepend-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="tests"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.action`]="{ item: { groupId, testId } }">
        <div style="width: 100px">
          <v-btn
            :to="{
              name: 'Result.Group.TestResult',
              params: { group_id: groupId, test_id: testId }
            }"
            color="primary"
            class="mb-1"
            >Result</v-btn
          >
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Group from "@/services/group";

export default {
  name: "Result.Group.Test",
  data() {
    return {
      totalData: 0,
      tests: [],
      loading: true,
      options: {
        search: undefined
      },
      headers: [
        { text: "Test Name", value: "testName" },
        { text: "Action", value: "action", sortable: false }
      ]
    };
  },
  watch: {
    options: {
      handler() {
        this.getTests();
      },
      deep: true
    }
  },
  created() {
    this.setNavbarTitle("Test List");
  },
  computed: {
    dataId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getTests() {
      this.loading = true;
      this.tests = [];
      const tests = await Group.findTests(this.dataId, this.options);
      this.tests = tests.data.data.tests;
      this.totalData = tests.data.data.total_data;
      this.loading = false;
    }
  }
};
</script>
